import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  FooterContainer,
  Language,
  Telefone,
} from "./styles";
import { useState } from "react";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setVisibility] = useState(false);

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="29px" height="29px" />
      </a>
    );
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
    setVisibility(false);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{"Contato"}</Language>
              <Telefone to="/">{"(11) 93204-0727"}</Telefone>
              <Para>{`Você tem alguma dúvida? Me envie uma mensagem.`}</Para>
              <a href="https://api.whatsapp.com/send/?phone=5511932040727&text=Ol%C3%A1%2C+gostaria+de+solicitar+uma+coleta+de+óleo&type=phone_number&app_absent=0">
                <Chat>{`Whatsapp`}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{"Empresa"}</Title>
              <Large onClick={() => scrollTo("about")} to="/">
                {"Sobre Nós"}
              </Large>
              <Large onClick={() => scrollTo("process")} to="/">
                {"Processos"}
              </Large>
              <Large onClick={() => scrollTo("gallery")} to="/">
                {"Galeria"}
              </Large>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{"Endereço"}</Language>
              <Para>São Paulo - SP</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="cad_logo.png"
                  aria-label="homepage"
                  width="110px"
                  height="100px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink href="https://www.cadlub.com.br" src="google.svg" />
              <SocialLink
                href="https://api.whatsapp.com/send/?phone=5511932040727&text=Ol%C3%A1%2C+gostaria+de+solicitar+uma+coleta+de+óleo&type=phone_number&app_absent=0"
                src="whatsapp.svg"
              />
              <SocialLink
                href="https://www.instagram.com/coletor_oleo_lubrificante_sp?igsh=NjZrNzhzeWJwYjV6"
                src="instagram.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default Footer;
